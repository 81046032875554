@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
    font-family: "Montserrat", sans-serif;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #3182ce;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

th{
    min-width: 10rem;
}

tr:nth-child(2n+2){
    background-color: #e9e9e9;
}

@tailwind base;
@tailwind components;
@tailwind utilities;